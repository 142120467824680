import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { ArrowRight32, ArrowLeft32 } from "@carbon/icons-react";

import ComponentBildSlider from "./component-bild-slider";
import ErrorBoundary from "../../../../error-boundary";

class ParagraphBilderSlider extends Component {

  render() {
    const sectionClassNames = classNames({
      "paragraph paragraph-bilder-slider": true,
      [`format-${this.props.content.fieldFormat}`]: true,
    });

    console.log(this.props.content);

    return (
      <section className={sectionClassNames}>
        <div className="container">
          <div className="row">
            <div
              className={classNames({
                "col-10": this.props.content.fieldFormat === "full",
                "col-lg-8 offset-lg-2": this.props.content.fieldFormat === "normal" && !this.props.content.fieldShowAsSlider,
                "col-lg-6 offset-lg-2": this.props.content.fieldFormat === "normal" && this.props.content.fieldShowAsSlider
              })}
            >
              <ErrorBoundary>
                <ComponentBildSlider
                  bilder={this.props.content.fieldBilderWCaption}
                  content={this.props.content}
                  globalCaption={this.props.content.fieldBildunterschrift}
                  showAsSlider={this.props.content.fieldShowAsSlider}
                />
              </ErrorBoundary>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

ParagraphBilderSlider.propTypes = {
  content: PropTypes.shape({
    fieldBildunterschrift: PropTypes.string,
    fieldBilderWCaption: PropTypes.arrayOf(
      PropTypes.shape({
        entity: PropTypes.shape({
          fieldMediaImage: PropTypes.shape({
            alt: PropTypes.string,
            title: PropTypes.string,
            style: PropTypes.shape({
              url: PropTypes.string.isRequired,
            }),
          }),
        }),
      })
    ),
    fieldFormat: PropTypes.oneOf(["center", "left", "right", "full"]),
  }),
};

export default ParagraphBilderSlider;
