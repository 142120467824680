import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { teaserNewsPropTypes } from "../../../../teaser-base/news/teaser-news";
import { teaserPersonPropTypes } from "../../../../teaser-base/person/teaser-person";
import OutsideClickHandler from "react-outside-click-handler";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";

import classNames from "classnames";
import moment from "moment";
import ErrorBoundary from "../../../../../error-boundary";
import ComponentExtendedTeaserOverviewSearch from "./component-extended-teaser-overview-search";

const weekdays = {
  Mo: "Montag",
  Tu: "Dienstag",
  We: "Mittwoch",
  Th: "Donnerstag",
  Fr: "Freitag",
  Sa: "Samstag",
  Su: "Sonntag",
};

const ComponentFilterCloud = ({
  wrap = true,
  preventEmptyResult = false,
  ...props
}) => {
  const [collapseOpen, setCollapseOpen] = useState(false);

  const filterCloud = useRef();

  const getTagsFromItems = (items, filterField) => {
    let tags = [];

    for (const item of items) {
      if (item[filterField] == null) {
        continue;
      }
      // Text field.
      if (typeof item[filterField] === "string") {
        if (!tags.some(({ entityId }) => entityId === item[filterField])) {
          tags = [
            ...tags,
            {
              entityId: item[filterField],
              name: item[filterField],
            },
          ];
        }

        continue;
      }

      // Single Entity reference field.
      if (
        typeof item[filterField] === "object" &&
        !Array.isArray(item[filterField]) &&
        item[filterField].entity.name !== null &&
        item[filterField].entity.entityId !== null
      ) {
        if (
          !tags.some(
            ({ entityId }) => entityId === item[filterField].entity.entityId
          )
        ) {
          tags = [...tags, item[filterField].entity];
        }

        continue;
      }

      // Multi Entity reference field.
      for (const tag of item[filterField]) {
        if (
          tag.entity !== null &&
          tag.entity.name !== null &&
          !tags.some(({ entityId }) => entityId === tag.entity.entityId)
        ) {
          tags = [...tags, tag.entity];
        }
      }
    }

    return tags;
  };

  const getFilters = (items, filtersInput) => {
    const filters = {};

    for (const filter of filtersInput) {
      filters[filter] = getTagsFromItems(items, filter);
    }

    return filters;
  };

  const tags = getFilters(props.items, props.filter);

  const getMonthsFromContent = () => {
    let months = [];
    for (const item of props.items) {
      if (
        !months.includes(moment(item.fieldDatumKurs.endValue).format("YYYY-MM"))
      ) {
        months = [
          ...months,
          moment(item.fieldDatumKurs.endValue).format("YYYY-MM"),
        ];
      }
      if (
        !months.includes(moment(item.fieldDatumKurs.value).format("YYYY-MM"))
      ) {
        months = [
          ...months,
          moment(item.fieldDatumKurs.value).format("YYYY-MM"),
        ];
      }
    }
    return months.sort();
  };

  const toggleCollapse = (type) => {
    setCollapseOpen(type === collapseOpen ? false : type);
    /* this.setState({
      collapseOpen: type === this.state.collapseOpen ? false : type
    }) */
  };

  const resetFilter = (type) => {
    setCollapseOpen(false);

    if (type === "month") {
      props.changeMonthFilter("reset");
    } else {
      props.changeDayFilter("reset");
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined" && window.innerWidth < 768) {
      const tagFilterHeight = filterCloud.current.querySelector(
        ".filter-type-schlagwort"
      ).clientHeight;
      const searchHeight =
        filterCloud.current.querySelector(".search").clientHeight;

      filterCloud.current.style.setProperty(
        "--sticky-top",
        `-${tagFilterHeight + searchHeight + 24}px`
      );
    }
  }, []);

  const filters = Object.keys(tags);

  let filter = filters.map((filterName, index) => {
    const items = tags[filterName];

    switch (filterName) {
      case "timeFilters":
        return (
          <div className="filter-wrapper">
            <OutsideClickHandler onOutsideClick={() => toggleCollapse(false)}>
              <div
                className="filter-wrapper filter-type-time-filters"
                key={index}
              >
                <div
                  className={`collapse-wrapper month ${
                    props.monthFilter.length >= 1 && !collapseOpen
                      ? "active"
                      : "inactive"
                  }`}
                >
                  <button>
                    <span
                      className="label"
                      onClick={() => toggleCollapse("month")}
                    >
                      Monat
                      {props.monthFilter.length >= 1 && (
                        <div className={"selected-options"}>
                          (
                          {props.monthFilter.map((month) => {
                            return (
                              <span className={"option-short"} key={month}>
                                {moment(month, "YYYY-MM").format("MMM")}
                              </span>
                            );
                          })}
                          )
                        </div>
                      )}
                    </span>
                    {props.monthFilter.length >= 1 && (
                      <div
                        className="reset-cross"
                        onClick={() => resetFilter("month")}
                      >
                        <span className="cross-icon" />
                      </div>
                    )}
                  </button>
                  <div
                    className={`collapsing ${
                      collapseOpen === "month" ? "show" : "hide"
                    }`}
                  >
                    <ul>
                      {getMonthsFromContent().map((month, monthIndex) => {
                        return (
                          <li
                            role="button"
                            tabIndex={0}
                            key={monthIndex}
                            onClick={() => props.changeMonthFilter(month)}
                          >
                            <div
                              className={`filter-option ${
                                props.monthFilter &&
                                props.monthFilter.includes(month)
                                  ? "active"
                                  : "false"
                              }`}
                            >
                              {moment(month, "YYYY-MM").format("MMMM ’YY")}
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
                <div
                  className={`collapse-wrapper day ${
                    props.dayFilter.length >= 1 && !collapseOpen
                      ? "active"
                      : "inactive"
                  }`}
                >
                  <button>
                    <span
                      className="label"
                      onClick={() => toggleCollapse("day")}
                    >
                      Wochentage
                      {props.dayFilter.length >= 1 && (
                        <div className={"selected-options"}>
                          (
                          {props.dayFilter.map((day) => (
                            <span className={"option-short"} key={day}>
                              {weekdays[day]}
                            </span>
                          ))}
                          )
                        </div>
                      )}
                    </span>
                    {props.dayFilter.length >= 1 && (
                      <div
                        className="reset-cross"
                        onClick={() => resetFilter("day")}
                      >
                        <span className="cross-icon" />
                      </div>
                    )}
                  </button>
                  <div
                    className={`collapsing ${
                      collapseOpen === "day" ? "show" : "hide"
                    }`}
                  >
                    <ul>
                      {Object.keys(weekdays).map((day, dayIndex) => (
                        <li
                          role="button"
                          tabIndex={0}
                          key={dayIndex}
                          onClick={() => props.changeDayFilter(day)}
                        >
                          <div
                            className={`filter-option ${
                              props.dayFilter && props.dayFilter.includes(day)
                                ? "active"
                                : "false"
                            }`}
                          >
                            {weekdays[day]}
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div
                  className={`collapse-wrapper ${
                    props.ferienFilter ? "active" : "false"
                  }`}
                >
                  <button onClick={() => props.changeFerienFilter()}>
                    <span className="label">Ferien</span>
                  </button>
                </div>
              </div>
            </OutsideClickHandler>
          </div>
        );
      case "fieldZielgruppe":
        return (
          <div
            className={`filter-wrapper filter-type-${filterName
              .toLowerCase()
              .replace("field", "")}`}
            key={index}
          >
            {items.map((item, index) => {
              if (
                preventEmptyResult &&
                typeof props.filterItems === "function"
              ) {
                const matchingItems = props.filterItems([
                  ...props.activeFilter,
                  {
                    ...item,
                    field: filterName,
                  },
                ]);

                if (matchingItems.length === 0) {
                  return null;
                }
              }

              return (
                <div
                  role="button"
                  tabIndex={0}
                  className={classNames({
                    btn: true,
                    "btn-secondary": true,
                    active:
                      props.activeFilter &&
                      props.activeFilter.filter(
                        (filterItem) =>
                          filterItem.entityId === item.entityId &&
                          filterItem.field === filterName
                      ).length > 0,
                  })}
                  key={index}
                  onClick={() =>
                    props.changeActiveFilter({
                      ...item,
                      field: filterName,
                    })
                  }
                >
                  <span className="name">{item.name}</span>
                </div>
              );
            })}
          </div>
        );
      default:
        return (
          <React.Fragment key={index}>
            <div
              className={`filter-wrapper filter-type-${filterName
                .toLowerCase()
                .replace("field", "")}`}
            >
              <Accordion allowZeroExpanded={true}>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <img
                        src="/circled-plus.svg"
                        className="plus-icon"
                        alt="mehr erfahren icon"
                      />
                      {props.labels && props.labels[index] ? (
                        <span>{props.labels[index]}</span>
                      ) : (
                        <span>Filtern</span>
                      )}
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <div className="row">
                      <div className="col-10 filter-items-wrapper">
                        {items.sort((a, b) =>  new Intl.Collator('de').compare(a.name, b.name)).map((item, index) => {
                          if (
                            preventEmptyResult &&
                            typeof props.filterItems === "function"
                          ) {
                            const matchingItems = props.filterItems([
                              ...props.activeFilter,
                              {
                                ...item,
                                field: filterName,
                              },
                            ]);

                            if (matchingItems.length === 0) {
                              return null;
                            }
                          }

                          return (
                            <div
                              role="button"
                              tabIndex={0}
                              className={classNames({
                                filter: true,
                                active:
                                  props.activeFilter &&
                                  props.activeFilter.filter(
                                    (filterItem) =>
                                      filterItem.entityId === item.entityId &&
                                      filterItem.field === filterName
                                  ).length > 0,
                              })}
                              key={index}
                              onClick={() =>
                                props.changeActiveFilter({
                                  ...item,
                                  field: filterName,
                                })
                              }
                            >
                              <span className="inktrap">↗</span>
                              <span className="cross-icon" />
                              <span className="name">{item.name}</span>
                            </div>
                          );
                        })}
                      </div>
                      {props.resetFilter &&
                        props.activeFilter
                          .map((item) => item.field)
                          .includes(filterName) && (
                          <div className={"reset-wrapper"}>
                            <div
                              role="button"
                              tabIndex={0}
                              className="filter filter-reset"
                              onClick={() => props.resetFilter(filterName)}
                            >
                              <span className="cross-icon" />
                              <span className="name">Filter löschen</span>
                            </div>
                          </div>
                        )}
                    </div>
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
            </div>
          </React.Fragment>
        );
    }
  });

  filter.push(
    <>
      {props.type === "kurse_uebersicht" && (
          <ErrorBoundary>
            <ComponentExtendedTeaserOverviewSearch
              changeActiveSearch={props.changeActiveSearch}
            />
          </ErrorBoundary>
        )}
    </>
  );

  return (
    <section className="filter-cloud" ref={filterCloud}>
      {wrap ? (
        <div className="container">
          <div className="row">{filter}</div>
        </div>
      ) : (
        <div>{filter}</div>
      )}
    </section>
  );
};

ComponentFilterCloud.propTypes = {
  /**
   * The active filter.
   */
  activeFilter: PropTypes.array,
  /**
   * The function to change the active filter.
   */
  changeActiveFilter: PropTypes.func.isRequired,
  /**
   * The filter function used to filter the items. Used for the "prevent zero
   * results" feature.
   */
  filterItems: PropTypes.func,
  /**
   * The items to be filtered.
   */
  items: PropTypes.arrayOf(
    PropTypes.oneOfType([teaserNewsPropTypes, teaserPersonPropTypes])
  ),
  /**
   * The name of the filtered field.
   */
  filter: PropTypes.arrayOf(PropTypes.string).isRequired,
  /**
   * The labels of the filters.
   */
  labels: PropTypes.arrayOf(PropTypes.string),
  /**
   * Whether to prevent zero results.
   */
  preventEmptyResult: PropTypes.bool,
  /**
   * Whether to wrap the filter cloud in a container.
   */
  wrap: PropTypes.bool.isRequired,
  /**
   * The function used to reset the filter(s)
   */
  resetFilter: PropTypes.func,
  /**
   * The function used to reset the filter(s)
   */
  resetFilterOn: PropTypes.arrayOf(PropTypes.string),
};

export default ComponentFilterCloud;
